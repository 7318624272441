import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentMasonry from "../components/content/contentMasonry"
import ContentList from "../components/content/contentList"
import LayoutContext from "../state/context/layout-context"
import ContentListToggleIcons from "../components/content/contentListToggleIcons"

export default ({
  data: {
    contentfulTag: { seoTitle, title, shortDescription, slug, canonical },
    allContentfulLayoutTemplate: { edges: content },
  },
  location,
}) => {
  const { layoutType, toggleLayout } = useContext(LayoutContext)

  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={`tag/${slug}`}
        canonical={`tag/${canonical}`}
        robots={`noindex, follow`}
      />
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold mt-6 mb-10 md:text-4xl">{title}</h1>
          <ContentListToggleIcons
            layoutType={layoutType}
            toggleLayout={toggleLayout}
          />
        </div>
        <ContentMasonry layoutType={layoutType} content={content} />
        <ContentList layoutType={layoutType} content={content} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query tagPageQuery($tagId: String!) {
    contentfulTag(id: { eq: $tagId }) {
      id
      title
      seoTitle
      shortDescription
      slug
      canonical
    }
    allContentfulLayoutTemplate(
      filter: { tags: { elemMatch: { id: { in: [$tagId] } } } }
      sort: { fields: published, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          shortDescription
          type
          published(formatString: "MMM DD, YYYY")
          image {
            id
            fluid(maxWidth: 580) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            description
          }
        }
      }
    }
  }
`
